import React from 'react';
import Picture from '../assets/aboutme3.png';

const About = ({ theme }) => {
  return (
    <div className={`flex flex-col items-center justify-center min-h-screen bg-${theme === 'light' ? 'light-bg' : 'dark-bg'} text-${theme === 'light' ? 'light-text' : 'dark-text'} p-8 lg:p-20`}>
      {/* Centered Header */}
      <h2 className="text-3xl font-semibold mb-8 text-center">About Me</h2>

      {/* Outer wrapper */}
      <div className="rounded-lg p-6 max-w-screen-lg w-full mx-auto">

        {/* Flex Container for Image and Text */}
        <div className="flex flex-col lg:flex-row items-start justify-between lg:space-x-6">

          {/* Image Section (above text on small screens, on the right on large screens) */}
          <div className="w-full lg:w-2/5 flex justify-center lg:justify-end mb-8 lg:mb-0 px-4 order-1 lg:order-2">
            <img src={Picture} alt="" className="w-full h-auto max-w-xs rounded-lg" />
          </div>

          {/* Text Section (below image on small screens, on the left on large screens) */}
          <div className="w-full lg:w-3/5 lg:text-left text-center mb-8 lg:mb-0 px-4 order-2 lg:order-1">
            <div className="p-4 rounded-lg">
              <p className="mt-4 text-lg leading-relaxed">
                My name is Huy Ho. I am currently an aspiring Software Engineer. I enjoy designing and building full stack
                and mobile apps, and I am passionate about using the tools and knowledge I have to create and work on products
                that will improve the lives of others. Besides coding, I have many interests (like climbing trees as shown on the right)
                and I believe that I am more than just a Leetcoding weapon. As a result, I hope you reach out to me if there are any questions
                that I can answer. Otherwise, please keep scrolling and thank you for checking out my website!
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default About;
