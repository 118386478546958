import React, { useState, useRef } from 'react';
import basketballImage from '../assets/basketball.png'; // Update the path as needed
import workoutVideo from '../assets/workout.mp4'; // Update the path as needed
import hikingImage from '../assets/hiking.png'; // Update the path as needed
import chessImage from '../assets/chess.png'; // Update the path as needed
import animeImage from '../assets/anime.png'; // Update the path as needed
import foodImage from '../assets/food.png'; // Update the path as needed

const hobbiesData = [
  {
    title: 'Basketball',
    content: 'I enjoy playing basketball in my free time. I actually played varsity in high school. I was not very good in high school, but I can still dominate your local YMCA runs.',
    media: basketballImage,
    type: 'image',
  },
  {
    title: 'Working Out',
    content: 'I love weightlifting. It helps me relax and allows to turn my brain off while I just focus on gaining some muscles. My PRs are 245 for bench and 345 for squat. I have never PRed for deadlift before, so I do not know what my PR is for that.',
    media: workoutVideo,
    type: 'video',
  },
  {
    title: 'Hiking',
    content: 'I love hiking. In the picture to the left is the Adirondacks Mountains. That was the heighest peak I have gone. I could not take pictures during this trip since it was for my leadership program and phones were forbidden (there was no service out there anyways so phones do not matter).',
    media: hikingImage,
    type: 'image',
  },
  {
    title: 'Chess',
    content: 'I like to play chess even though I am not the best. I like to just play with friends. On the left are my stats, not very impressive, but trust me, I can surprise people here and there.',
    media: chessImage,
    type: 'image',
  },
  {
    title: 'Anime',
    content: 'I love watching anime, and on the left is Zoro, my favorite anime character from my favorite anime, One Piece. I have watched many other animes as well, but none has better world building in my opinion than One Piece.',
    media: animeImage,
    type: 'image',
  },
  {
    title: 'Food',
    content: 'I love trying out new places to eat and discovering different cuisines. However, my favorite cuisine still has to be Vietnamese. When I went on a trip to Vietnam, I tried Bun Mam (on the left) and it changed my life. Now I crave it all the time since in the U.S., it is rare to find it.',
    media: foodImage,
    type: 'image',
  },
];

const Hobbies = ({ theme }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={`hobbies-container bg-${theme === 'light' ? 'white' : 'dark-bg'} text-${theme === 'light' ? 'light-text' : 'dark-text'} p-6 lg:p-16 min-h-screen pt-32`}>
      <div className="max-w-screen-lg w-full mx-auto">
        <h1 className="text-3xl font-semibold text-center mb-10 mt-12">My Hobbies</h1>
        <div className="space-y-4">
          {hobbiesData.map((hobby, index) => (
            <div
              key={index}
              className={`border rounded-lg overflow-hidden shadow-md transition-all duration-300 ${theme === 'light' ? 'bg-white' : 'bg-navbar-dark-bg'}`}
            >
              <button
                className={`w-full text-left p-4 focus:outline-none flex justify-between items-center border-b ${theme === 'light' ? 'bg-white border-gray-200' : 'bg-navbar-dark-bg border-gray-200'} ${theme === 'light' ? 'text-gray-900' : 'text-white'} hover:bg-opacity-90`}
                onClick={() => toggleAccordion(index)}
              >
                <span className="text-lg font-medium">{hobby.title}</span>
                <svg
                  className={`transform transition-transform duration-300 ${activeIndex === index ? 'rotate-180' : 'rotate-0'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="20"
                  height="20"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <div
                ref={(el) => (contentRefs.current[index] = el)}
                className={`transition-max-height duration-500 ease-in-out overflow-hidden ${theme === 'light' ? 'bg-white' : 'bg-dark-bg'} ${theme === 'light' ? 'text-gray-700' : 'text-gray-300'}`}
                style={{
                  maxHeight: activeIndex === index ? `${contentRefs.current[index]?.scrollHeight}px` : '0',
                }}
              >
                <div className="p-4 flex flex-col md:flex-row gap-4 items-center">
                  {/* Media (Image or Video) */}
                  <div className="flex-shrink-0">
                    {hobby.type === 'image' ? (
                      <img
                        src={hobby.media}
                        alt={`${hobby.title} media`}
                        className="w-full md:w-48 h-auto rounded-lg object-cover shadow-lg"
                      />
                    ) : (
                      <video
                        src={hobby.media}
                        controls
                        className="w-full md:w-48 h-auto rounded-lg object-cover shadow-lg"
                      >
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>

                  {/* Hobby Content */}
                  <div className="flex-grow text-center md:text-left">
                    <p className="text-base leading-relaxed">{hobby.content}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hobbies;
