import React from 'react';
import meImg from '../assets/me.jpeg';
import emailIcon from '../assets/email-light.png';  
import githubLight from '../assets/github-light.png';  
import githubDark from '../assets/github-dark.png';    
import linkedinIcon from '../assets/linkedin-light.png'; 
import CV from '../assets/HuyHo-Resume.pdf';

const Home = ({ theme }) => {
  const githubIcon = theme === 'light' ? githubLight : githubDark; // Only GitHub changes based on theme

  return (
    <section 
      id="home" 
      className={`flex flex-col justify-center items-center gap-5 text-center h-screen min-h-[500px] ${theme === 'light' ? 'bg-light-bg text-black' : 'bg-dark-bg text-white'}`}
    >
      <div className="relative">  
        <img
          src={meImg}
          alt=""
          className="max-w-[200px] rounded-full object-cover"
        />
      </div>
      <div className="flex flex-col gap-5"> 
        <h1 
          className={`text-2xl font-bold uppercase ${theme === 'light' ? 'text-black' : 'text-white'}`} 
        >
          Huy Ho
        </h1>
        <h2 
          className={`text-xl uppercase ${theme === 'light' ? 'text-black' : 'text-white'}`} 
        >
          Software Developer
        </h2>
        <span 
          className="flex gap-6 justify-center" 
        >
          <a href="mailto:bigpuppy@gmail.com" target="_blank" rel="noopener noreferrer">
            <img src={emailIcon} alt="Email icon" className="w-7" />
          </a>
          <a href="https://github.com/huyho1" target="_blank" rel="noopener noreferrer">
            <img src={githubIcon} alt="Github icon" className="w-7" />
          </a>
          <a href="https://linkedin.com/in/huyho1" target="_blank" rel="noopener noreferrer">
            <img src={linkedinIcon} alt="LinkedIn icon" className="w-7" />
          </a>
        </span>
        <p 
          className={`max-w-[24ch] mx-auto ${theme === 'light' ? 'text-black' : 'text-white'}`} 
        >
          Welcome to my personal website!
        </p>
        <a href={CV} download="Huy_Ho_Resume.pdf">
          <button 
            className={`bg-${theme === 'light' ? 'white' : 'blue-500'} text-${theme === 'light' ? 'black' : 'white'} rounded-full w-32 h-12 text-lg font-bold shadow-md transition-transform duration-200 ease-in-out transform hover:scale-105 active:translate-y-1`}
          >
            Resume
          </button>
        </a>
      </div>
    </section>
  );
}

export default Home;
