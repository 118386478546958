import React, { useState, useEffect, useRef } from 'react';
import Picture from '../assets/wpilogo.png';  // Import the WPI logo image

const Education = ({ theme }) => {
  const [showCoursework, setShowCoursework] = useState(false);
  const [scrollbarVisible, setScrollbarVisible] = useState(false);
  const contentRef = useRef(null);

  // Dummy coursework data
  const coursework = [
    'CS 1101 - Introduction To Program Design',
    'CS 2103 - Accelerated Object-Oriented Design Concepts',
    'FIN 2070 - Fundamentals of Finance',
    'CS 2011 - Introduction To Machine Organization And Assembly Language',
    'CS 2223 - Algorithms',
    'CS 2303 - Systems Programming Concepts',
    'CS 3431 - Database Systems I',
    'CS 3013 - Operating Systems',
    'CS 3733 - Software Engineering',
    'DS 2010 - Data Science II: Modeling and Data Analysis'
  ];

  const toggleCoursework = () => {
    setShowCoursework(!showCoursework);
  };

  useEffect(() => {
    const content = contentRef.current;
    if (content) {
      const hasOverflow = content.scrollHeight > content.clientHeight;
      setScrollbarVisible(hasOverflow);
    }
  }, [showCoursework]);

  return (
    <div className={`education-container flex flex-col items-center justify-center min-h-screen bg-${theme === 'light' ? 'light-bg' : 'dark-bg'} text-${theme === 'light' ? 'light-text' : 'dark-text'} p-8 lg:p-20`}>
      {/* Centered Header */}
      <h2 className="text-4xl font-semibold mb-8 text-center">Education</h2>

      {/* Outer wrapper */}
      <div className={`rounded-lg p-6 max-w-screen-lg w-full mx-auto`}>

        <div className="flex flex-col lg:flex-row items-start justify-between">

          {/* Image Section (centered for small screens) */}
          <div className="lg:w-2/5 w-full flex justify-center lg:justify-start items-center mb-8 lg:mb-0 px-4 order-1">
            <div className="w-full h-auto max-w-xs rounded-lg">
              {/* Link around the image */}
              <a href="https://www.wpi.edu/" target="_blank" rel="noopener noreferrer">
                <img src={Picture} alt="WPI Logo" className="w-full h-auto max-w-xs rounded-lg" />
              </a>
            </div>
          </div>

          {/* Text Section with scrolling */}
          <div className="lg:w-3/5 text-left px-4 order-2">
            <div
              ref={contentRef}
              className={`p-4 h-[400px] overflow-y-scroll rounded-lg custom-scrollbar ${scrollbarVisible ? 'scrollbar-visible' : 'scrollbar-hidden'}`}
            >
              <p className="text-lg">M.S. in Computer Science</p>
              <p className="text-lg">B.S. in Computer Science and Data Science, Minor in Fintech</p>
              <p className="text-lg">Expected Graduation Date: December 2027</p>
              <p className="mt-4 text-lg">
                I am leveraging my education of computer science and data science alongside a minor in fintech
                to help me achieve my goal of working with large amounts of data and machine learning.
              </p>

              {/* Relevant Coursework Section */}
              <div className="relevant-coursework mt-6">
                <button
                  onClick={toggleCoursework}
                  className="flex items-center text-lg font-medium hover:text-gray-900 focus:outline-none"
                >
                  Relevant Coursework:
                  <span className="ml-2 transform transition-transform duration-300">
                    {showCoursework ? '-' : '+'}
                  </span>
                </button>

                {/* Coursework List */}
                {showCoursework && (
                  <div className="mt-2 p-4 rounded-lg bg-transparent">
                    <ul className={`list-disc text-left ${theme === 'light' ? 'text-black' : 'text-white'}`}>
                      {coursework.map((course, index) => (
                        <li key={index}>{course}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Education;
