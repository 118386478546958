import React, { useState, useEffect, useRef } from 'react';
import logoLight from '../assets/newlogo.png';
import logoDark from '../assets/newlogo2.png';
import sun from '../assets/newsun.png';
import moon from '../assets/newmoon.png';

const Navbar = ({ theme, onThemeToggle }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeSection, setActiveSection] = useState('');
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoverTrigger, setIsHoverTrigger] = useState(false);
  const [isLinkClick, setIsLinkClick] = useState(false);

  const navRef = useRef(null);
  const lastScrollY = useRef(window.pageYOffset);

  // Custom smooth scroll function
  const smoothScrollTo = (targetY, duration) => {
    const startY = window.pageYOffset;
    const distanceY = targetY - startY;
    const startTime = performance.now();

    const easeInOutQuad = (time, from, distance, duration) => {
      time /= duration / 2;
      if (time < 1) return (distance / 2) * time * time + from;
      time--;
      return (-distance / 2) * (time * (time - 2) - 1) + from;
    };

    const scroll = (currentTime) => {
      const timeElapsed = currentTime - startTime;
      const nextScroll = easeInOutQuad(timeElapsed, startY, distanceY, duration);

      window.scrollTo(0, nextScroll);

      if (timeElapsed < duration) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = sectionId ? document.getElementById(sectionId) : document.body;
    if (section) {
      const targetY = section.getBoundingClientRect().top + window.pageYOffset;
      smoothScrollTo(targetY, 600);
      setIsLinkClick(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setIsDropdownOpen(false);
      }
    };

    const handleScroll = () => {
      // Skip visibility logic if the dropdown is open
      if (isDropdownOpen) {
        return;
      }

      const scrollY = window.pageYOffset;

      if (!isLinkClick) {
        setIsHoverTrigger(false);

        if (!isHovered) {
          if (scrollY > lastScrollY.current && scrollY > 100) {
            setIsNavbarVisible(false);
          } else {
            setIsNavbarVisible(true);
          }
        }
      }

      lastScrollY.current = scrollY;
    };

    const handleMouseMove = (e) => {
      if (e.clientY <= 80) {
        if (!isNavbarVisible) {
          setIsNavbarVisible(true);
          setIsHoverTrigger(true);
        }
        setIsHovered(true);
      } else {
        setIsHovered(false);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isHovered, isNavbarVisible, isLinkClick, isDropdownOpen]);

  useEffect(() => {
    if (isLinkClick) {
      const timer = setTimeout(() => setIsLinkClick(false), 600); // Match the smooth scroll duration
      return () => clearTimeout(timer);
    }
  }, [isLinkClick]);

  const handleMouseLeave = () => {
    if (isHoverTrigger && !isDropdownOpen) {
      setIsNavbarVisible(false);
    }
  };

  const handleToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const themeIcon = theme === 'light' ? sun : moon;
  const logo = theme === 'light' ? logoLight : logoDark;
  const underlineColor = theme === 'light' ? 'border-black' : 'border-blue-500';

  useEffect(() => {
    const updateActiveSection = () => {
      const sections = ['about', 'education', 'projects', 'experience', 'hobbies', 'contact'];
      let currentSection = '';

      sections.forEach(sectionId => {
        const section = document.getElementById(sectionId);
        if (section) {
          const rect = section.getBoundingClientRect();
          if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
            currentSection = sectionId;
          }
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', updateActiveSection);
    updateActiveSection(); // Update immediately on mount

    return () => window.removeEventListener('scroll', updateActiveSection);
  }, []);

  return (
    <nav
      ref={navRef}
      onMouseLeave={handleMouseLeave}
      className={`fixed top-0 left-0 w-full z-10 transition-transform duration-300 ${isNavbarVisible ? 'translate-y-0' : '-translate-y-full'} ${theme === 'light' ? 'bg-light-bg text-light-text' : 'bg-navbar-dark-bg text-dark-text'} ${theme === 'light' ? 'border-gray-200' : 'border-navbar-border'} shadow-md`}
    >
      <div className="w-full flex items-center justify-between p-4">
        {/* Logo */}
        <a href="home" onClick={(e) => scrollToSection(e, null)} className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} className="h-12 w-auto" alt="Logo" />
        </a>

        {/* Navigation Links */}
        <div className="flex-grow flex items-center justify-center">
          <div className="hidden md:flex space-x-6">
            {['about', 'education', 'projects', 'experience', 'hobbies', 'contact'].map(sectionId => (
              <a
                key={sectionId}
                href={`#${sectionId}`}
                onClick={(e) => scrollToSection(e, sectionId)}
                className={`text-lg ${activeSection === sectionId ? `border-b-2 ${underlineColor} transition-all duration-300` : ''} ${theme === 'light' ? 'text-gray-900 hover:text-gray-700' : 'text-white hover:text-gray-300'} pb-1`}
              >
                {sectionId.charAt(0).toUpperCase() + sectionId.slice(1)}
              </a>
            ))}
          </div>
        </div>

        {/* Theme Toggle Button */}
        <div className="relative inline-block text-left">
          <button 
            type="button" 
            className="inline-flex items-center p-2 w-12 h-12 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-transparent dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-transparent" 
            aria-controls="navbar-dropdown"
            aria-expanded={isDropdownOpen}
            onClick={handleToggle}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
          </button>
          {isDropdownOpen && isMobile && (
            <div className={`absolute right-0 mt-6 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 ${theme === 'light' ? 'bg-white border-gray-200 shadow-md' : 'bg-navbar-dark-bg border border-gray-700 shadow-lg'}`}>
              <div className="py-1">
                {['about', 'education', 'projects', 'experience', 'hobbies', 'contact'].map(sectionId => (
                  <a
                    key={sectionId}
                    href={`#${sectionId}`}
                    onClick={(e) => scrollToSection(e, sectionId)}
                    className={`block px-4 py-2 text-lg ${activeSection === sectionId ? `underline ${underlineColor}` : ''} ${theme === 'light' ? 'text-gray-900 hover:bg-gray-100 hover:text-gray-900' : 'text-white hover:bg-gray-700 hover:text-white'}`}
                  >
                    {sectionId.charAt(0).toUpperCase() + sectionId.slice(1)}
                  </a>
                ))}
                <a href="home" onClick={onThemeToggle} className={`block px-4 py-2 text-lg ${theme === 'light' ? 'text-gray-900 hover:bg-gray-100 hover:text-gray-900' : 'text-white hover:bg-gray-700 hover:text-white'}`}>
                  {theme === 'light' ? 'Light Mode' : 'Dark Mode'}
                </a>
              </div>
            </div>
          )}
          {!isMobile && (
            <button onClick={onThemeToggle} className="hidden md:flex items-center p-2 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-transparent dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-transparent">
              <img src={themeIcon} className="h-7 w-7 bg-transparent" alt="Theme toggle icon" />
            </button>         
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
