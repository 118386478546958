import React, { useState, useEffect, useRef, useCallback } from 'react';
import chatIcon from '../assets/chat-icon.png';

const Chat = ({ theme }) => {
  const [visible, setVisible] = useState(false);
  const [messages, setMessages] = useState([
    { sender: 'Bot', text: "Hello! I am Huy's assistant." },
    { sender: 'Bot', text: "Ask me any questions you may have about Huy and I will try to answer them." }
  ]);
  const [userInput, setUserInput] = useState('');
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [innerChatHeight, setInnerChatHeight] = useState(window.innerHeight - 250 - 130);
  const [scrollbarVisible, setScrollbarVisible] = useState(false);
  const inputRef = useRef(null);
  const chatBoxRef = useRef(null);

  // Memoize the handleSend function to avoid unnecessary re-creations
  const handleSend = useCallback(() => {
    if (userInput.trim() === '') return;
    const newMessage = { sender: 'You', text: userInput };

    // Your chatbot logic here
    const response = "I'm sorry, but this chat is currently undergoing rennovations, so it will not be operating right now. Please come back again!";
    
    // Update messages state with both new message and bot response
    setMessages((prevMessages) => [...prevMessages, newMessage, { sender: 'Bot', text: response }]);
    setUserInput('');
  }, [userInput]);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSend();
    }
  }, [handleSend]);

  useEffect(() => {
    const updateDimensions = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
      setInnerChatHeight(window.innerHeight - 250 - 130);
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (chatBoxRef.current) {
      const hasOverflow = chatBoxRef.current.scrollHeight > chatBoxRef.current.clientHeight;
      setScrollbarVisible(hasOverflow);
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight; // Scroll to the bottom
    }
  }, [messages, innerChatHeight]);

  // Set the height and width threshold
  const heightThreshold = 500;
  const widthThreshold = 400;

  if (windowHeight < heightThreshold || windowWidth < widthThreshold) {
    return null; // Do not render the chat icon and chatbox if window height or width is less than the threshold
  }

  return (
    <div className="fixed bottom-5 right-5 z-50"> {/* Increase z-index here */}
      <button
        onClick={() => setVisible(!visible)}
        className="w-16 h-16 rounded-full p-0 border-none outline-none bg-transparent"
        style={{ border: 'none' }}
      >
        <img src={chatIcon} alt="Chat Icon" className="w-full h-full" style={{ border: 'none' }} />
      </button>
      {visible && (
        <div className={`absolute bottom-20 right-0 w-80 p-4 border rounded-lg shadow-lg ${theme === 'light' ? 'bg-white border-gray-200' : 'bg-gray-800 border-gray-700'} ${scrollbarVisible ? 'scrollbar-visible' : 'scrollbar-hidden'} z-50`}> {/* Ensure z-index here */}
          <div className="relative flex items-center justify-center mb-4">
            <p className={`font-bold ${theme === 'light' ? 'text-black' : 'text-white'}`}>AI Assistant</p>
          </div>
          <div className="overflow-y-scroll mb-4 pr-2 custom-scrollbar" style={{ height: `${innerChatHeight}px` }} ref={chatBoxRef}>
            <div>
              {messages.map((msg, index) => (
                <div key={index} className={`flex ${msg.sender === 'You' ? 'justify-end' : 'justify-start'} mb-2`}>
                  <div className={`p-2 rounded-lg max-w-xs ${msg.sender === 'You' ? (theme === 'light' ? 'bg-blue-500 text-white ml-10' : 'bg-blue-600 text-white ml-10') : (theme === 'light' ? 'bg-gray-200 text-black mr-10' : 'bg-gray-700 text-white mr-10')}`}>
                    {msg.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex">
            <input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              ref={inputRef}
              className={`flex-1 p-2 border rounded-l-lg ${theme === 'light' ? 'border-gray-300' : 'border-gray-600'} ${theme === 'light' ? 'bg-white text-black' : 'bg-gray-700 text-white'}`}
              placeholder="Type your message..."
            />
            <button
              onClick={handleSend}
              className={`bg-blue-500 text-white p-2 rounded-r-lg ${theme === 'light' ? 'hover:bg-blue-600' : 'hover:bg-blue-800'}`}
            >
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
