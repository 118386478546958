import React, { useState } from 'react';

const Contact = ({ theme }) => {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "61f39f85-6524-4926-a56c-432706ba306e");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className={`contact-container flex flex-col items-center justify-center bg-${theme === 'light' ? 'light-bg' : 'dark-bg'} text-${theme === 'light' ? 'light-text' : 'dark-text'} p-8 lg:p-20 min-h-screen pt-16`}>
      <div className="max-w-screen-lg w-full mx-auto">
        <h1 className="text-4xl font-bold mb-4 text-center">Contact Me</h1>
        <form className="w-full max-w-lg mx-auto" onSubmit={onSubmit}>
          <div className="mb-4">
            <label className="block text-lg mb-2" htmlFor="name">Name</label>
            <input
              className={`w-full p-2 border rounded ${theme === 'light' ? 'bg-white text-black' : 'bg-gray-800 text-white'}`}
              type="text"
              id="name"
              name="name"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-lg mb-2" htmlFor="email">Email</label>
            <input
              className={`w-full p-2 border rounded ${theme === 'light' ? 'bg-white text-black' : 'bg-gray-800 text-white'}`}
              type="email"
              id="email"
              name="email"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-lg mb-2" htmlFor="message">Message</label>
            <textarea
              className={`w-full p-2 border rounded ${theme === 'light' ? 'bg-white text-black' : 'bg-gray-800 text-white'}`}
              id="message"
              name="message"
              required
            ></textarea>
          </div>
          <button className="bg-blue-500 text-white p-2 rounded" type="submit">Send</button>
        </form>
        <span className="mt-4 text-center text-lg">{result}</span>
      </div>
    </div>
  );
};

export default Contact;
