import React, { useState } from 'react';
import firstPic from '../assets/wpilogo.png';
import secondPic from '../assets/quirklogo.png';
import thirdPic from '../assets/quincycollegelogo.png';
import fourthPic from '../assets/nzonelogo.png';
import fifthPic from '../assets/c5logo.png';
import fillerPic1 from '../assets/qc.png'; 
import fillerPic2 from '../assets/nzone.png'; 
import fillerPic3 from '../assets/c5.png';

const experiences = [
  { id: 1, title: 'Lab Assistant', caption: 'WPI HCI Lab', timePeriod: 'September 2023 - current', img: firstPic, link: 'https://wp.wpi.edu/hcilab/' },
  { id: 2, title: 'Finance Assistant', caption: 'Quirk Auto Dealers', timePeriod: 'May 2024 - current', img: secondPic, link: 'https://www.quirkchevy.com/new-vehicles/equinox/?gad_source=1&gclid=Cj0KCQjw9Km3BhDjARIsAGUb4nz8dk6vJiGPtaFt5RXNwRxQvhLudLxguwSAa-m47p0yoPfpCDxZEW4aAm9aEALw_wcB' },
  { id: 3, title: 'IT Intern', caption: 'Quincy College', timePeriod: 'June 2022 - August 2022', img: thirdPic, link: 'https://www.quincycollege.edu/' },
  { id: 4, title: 'Soccer Coach', caption: 'N Zone Sports', timePeriod: 'April 2022 - June 2023', img: fourthPic, link: 'https://nzonesports.com/metroboston/locations/youth-sports-leagues-in-braintree-ma' },
  { id: 5, title: 'Leadership Program', caption: 'C5 Crossroads', timePeriod: 'June 2018 - May 2023', img: fifthPic, link: 'https://www.c5leaders.org/' },
];

const Experience = ({ theme }) => {
  const [showPopup, setShowPopup] = useState(null);

  const handleLearnMoreClick = (id) => {
    setShowPopup(id);
  };

  const handleClosePopup = () => {
    setShowPopup(null);
  };

  const getPopupContent = (id) => {
    let fillerPic;
    let fillerText;
    
    switch (id) {
      case 1:
        fillerPic = null;
        fillerText = "Collects data from large-scale experiments and assists in conducting research on human-computer interaction with a focus on brain-computer interfaces, physiological computing, touch-sensitive knitted fabrics and augmented reality.";
        break;
      case 2:
        fillerPic = null;
        fillerText = "Maintain records, perform calculations, and communicate with customers, banks and insurance companies. Assist with the completion of sold vehicles paperwork, ensuring that all documents required for the sale are included, correct title, lien information, taxes etc., on all transactions are complete.";
        break;
      case 3:
        fillerPic = fillerPic1;
        fillerText = "Provided in-office IT support and troubleshooted technical issues. Built, set up and configured 500+ PCs, laptops, printers, TVs and routers with new Windows settings and applications. Did research on cybersecurity systems that could be implemented.";
        break;
      case 4:
        fillerPic = fillerPic2;
        fillerText = "Taught kids from ages 4-13 to play soccer alongside valuable lessons such as communication, teamwork, collaboration, etc. Collaborated with parents on accommodating certain kid’s specific needs.";
        break;
      case 5:
        fillerPic = fillerPic3;
        fillerText = "Completed five-year youth leadership program including overnight hiking expeditions, group projects, and community service. Helped plan College Tours and ACT Now Summit.";
        break;
      default:
        fillerPic = null;
        fillerText = "No additional information available.";
    }

    const selectedExperience = experiences.find(experience => experience.id === id);

    return (
      <div className="flex flex-col items-center justify-center text-center">
        {selectedExperience && (
          <a
            href={selectedExperience.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline mb-4 text-xl font-bold"
          >
            {selectedExperience.caption}
          </a>
        )}
        {fillerPic && (
          <img
            src={fillerPic}
            alt={`Popup Experience ${id}`}
            className="w-64 h-64 mb-4 rounded-lg shadow-lg object-cover"
          />
        )}
        <p className="text-base text-gray-700 leading-relaxed">
          {fillerText}
        </p>
      </div>
    );
  };

  return (
    <div
      className={`experience-container flex flex-col items-center justify-center min-h-screen bg-${theme === 'light' ? 'light-bg' : 'dark-bg'} text-${theme === 'light' ? 'light-text' : 'dark-text'} p-8 lg:p-16`}
    >
      <h2 className="text-center text-4xl font-bold mb-8">Experience</h2>
      <div className="flex flex-wrap justify-center gap-8 max-w-screen-lg mx-auto">
        {experiences.map((experience) => (
          <div
            key={experience.id}
            className={`experience-card p-6 rounded-lg shadow-lg bg-${theme === 'light' ? 'white' : 'gray-800'} text-${theme === 'light' ? 'black' : 'white'} text-center w-72`}
          >
            <img
              src={experience.img}
              alt={`Experience ${experience.id}`}
              className="mx-auto w-24 h-24 rounded-full mb-4"
            />
            <p className="text-lg font-semibold">{experience.title}</p>
            <p className={`mt-2 text-${theme === 'light' ? 'gray-600' : 'gray-300'}`}>{experience.caption}</p>
            <p className={`mt-1 text-${theme === 'light' ? 'gray-500' : 'gray-400'}`}>{experience.timePeriod}</p>
            <button
              onClick={() => handleLearnMoreClick(experience.id)}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Learn More
            </button>
          </div>
        ))}
      </div>

      {showPopup !== null && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="relative bg-white p-8 rounded-lg max-w-screen-lg mx-auto shadow-xl">
            <button
              onClick={handleClosePopup}
              className="absolute top-2 right-2 text-2xl font-bold text-black"
            >
              ×
            </button>
            {getPopupContent(showPopup)}
          </div>
        </div>
      )}
    </div>
  );
};

export default Experience;
